<template>
<div>
  <a-table
      :pagination="false"
      :columns="appColumns"
      :data-source="appData"
      :row-key="record => record.id">
    <span slot="time" slot-scope="text, record">
      <a-range-picker @change="e =>onChange(e,record)"/>
    </span>
    <span slot="system" slot-scope="text, record">
      <a @click="lookBtn(record)">查询</a>
    </span>
<!--    <span slot="count" slot-scope="text, record">-->

<!--    </span>-->
    <span slot="dataExport" slot-scope="text, record">
      <span v-if="!record.count" style="color: #a3a1a1">导出</span>
      <a v-else @click="reportBtn(record)">导出</a>
    </span>
  </a-table>
</div>
</template>

<script>
import {pageType, pageTypeCount, reportPageExport} from "@/service/appDataExport";
import moment from "moment";
import headerConfig from '@/service/api_header_config'
const appColumns = [
  {
    title: '分类',
    key: 'name',
    dataIndex: 'name',
    width:'40%',
  },
  {
    title: '时间',
    scopedSlots: { customRender: 'time' },
    width:'20%',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'system' },
  },
  {
    title: '查询条数',
    key: 'count',
    dataIndex: 'count',
    scopedSlots: { customRender: 'count' },
  },
  {
    title: '数据导出',
    scopedSlots: { customRender: 'dataExport' },
  }
];
export default {
name: "AppDataExport",
  data(){
  return{
    appColumns,
    appData:[],
  }
  },
  created() {
  this.getList()
  },
  methods:{
    //获取app内容数据列表
    async getList() {
      const response = await pageType()
      if(response.code === 0){
        // this.appData = response.data
        //  获取接口时向数据插入字段  count默认为零
        response.data.forEach((list)=>{
          this.appData.push({
            id:list.id,
            name:list.name,
            keywords:list.keywords,
            count:0,
          })
        })
      }else {
        this.$message.warning(response.message)
      }
    },
    onChange(value,row){
      let start = moment(value[0]).format('YYYY-MM-DD')
      let end = moment(value[1]).format('YYYY-MM-DD')

      // let start = value[0]
      // let end = value[1]
      this.appData.forEach((list)=>{
        if(list.id === row.id){
          list.startTime = start
          list.endTime = end
        }
      })
    },
    async lookBtn(row) {
      let data = {
        start_time: row.startTime + ' 00:00:00',
        end_time: row.endTime + ' 23:59:59',
        pageType: row.keywords
      }
      const response = await pageTypeCount(data)
      if (response.code === 0) {
        this.appData.forEach((list)=>{
          if(list.id === row.id){
            list.count = response.data
          }
        })
      }
    },

    async reportBtn(row) {
      // let data = {
      //   start_time: row.startTime + ' 00:00:00',
      //   end_time: row.endTime + ' 23:59:59',
      //   pageType: row.keywords,
      //   // ExelName:row.name
      // }
      // await reportPageExport(data)
      const header = headerConfig.api_header
      window.open(header+"/api/reportPageExport?start_time="+row.startTime+' 00:00:00'+"&end_time="+row.endTime+' 23:59:59'+"&pageType="+row.keywords)
    },
  }
}
</script>

<style scoped>

</style>
